<template>
  <header id="the-header" class="p-pt-3 p-pb-1">
    <div id="header" class="p-d-flex p-jc-between">
      <router-link :to="{ name: 'Calendar' }">
        <Logo :height="70" :width="200" />
      </router-link>
      <div id="menu">
        <Button
          icon="pi pi-user"
          class="p-button-rounded p-button-text"
          v-tooltip.top="'Account'"
          @click="click('Account')"
        />
        <Button
          v-if="$store.getters.isSuper || canUpdateProject"
          @click="toggle"
          icon="pi pi-cog"
          class="p-button-rounded p-button-text"
          v-tooltip.top="'Settings'"
        />
        <Button
          @click="$store.dispatch('logout')"
          icon="pi pi-power-off"
          class="p-button-text"
          v-tooltip.top="'Logout'"
        />
        <OverlayPanel ref="settings" :dismissable="true">
          <ul>
            <li>
              <Button
                label="Projects"
                icon="pi pi-file"
                class="p-button-text p-button-sm"
                @click="click('ProjectIndex')"
              />
            </li>
            <li v-if="$store.getters.isSuper">
              <Button
                label="Patients"
                icon="pi pi-id-card"
                class="p-button-text p-button-sm"
                @click="click('PatientIndex')"
              />
            </li>
            <li v-if="$store.getters.isSuper">
              <Button
                label="Locations"
                icon="pi pi-map-marker"
                class="p-button-text p-button-sm"
                @click="click('LocationIndex')"
              />
            </li>
            <li v-if="$store.getters.isSuper">
              <Button
                label="Users"
                icon="pi pi-users"
                class="p-button-text p-button-sm"
                @click="click('UserIndex')"
              />
            </li>
            <li v-if="$store.getters.isSuper">
              <Button
                label="Permissions"
                icon="pi pi-lock"
                class="p-button-text p-button-sm"
                @click="click('PermissionIndex')"
              />
            </li>
            <li v-if="$store.getters.isSuper">
              <Button
                label="Email Templates"
                icon="pi pi-envelope"
                class="p-button-text p-button-sm"
                @click="click('EmailIndex')"
              />
            </li>
          </ul>
        </OverlayPanel>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Logo from '@/components/Logo'
import Button from 'primevue/button'
import OverlayPanel from 'primevue/overlaypanel'
import Tooltip from 'primevue/tooltip'
import { getPermissions } from '@/helpers/permissions'

export default {
  components: { Logo, Button, OverlayPanel },
  directives: {
    tooltip: Tooltip
  },
  setup() {
    const settings = ref(null)
    const router = useRouter()
    const { canUpdateProject } = getPermissions()

    function toggle(event) {
      settings.value.toggle(event)
    }

    function click(name) {
      settings.value.hide()
      router.push({ name })
    }

    return { settings, toggle, click, canUpdateProject }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
header {
  background: $brand_color;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#header {
  @include app-container;
}
.p-overlaypanel {
  margin-top: 0;
  &:after,
  &:before {
    display: none;
  }
  ::v-deep(.p-overlaypanel-content) {
    padding: 0.5rem;
  }
  .p-button {
    width: 100%;
    text-align: left;
  }
}
</style>
