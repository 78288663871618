import { createWebHistory, createRouter } from 'vue-router'
import store from '../store/index.js'
import { canAccess } from '@/helpers/permissions'

let entryUrl = null
const guard = async (to, from, next) => {
  if (store.state.userId) {
    if (entryUrl) {
      const url = entryUrl
      entryUrl = null
      return next(url) // goto stored url
    } else {
      switch (to.name) {
        case 'CreateBooking':
          if (!canAccess('Create Booking', store.state)) {
            return next('/')
          }
          break
        case 'ProjectIndex':
        case 'ProjectCreate':
        case 'ProjectUpdate':
          if (!canAccess('Update Project', store.state)) {
            return next('/')
          }
          break
        case 'UserIndex':
        case 'UserCreate':
        case 'UserUpdate':
        case 'Locations':
        case 'Patients':
        case 'EmailIndex':
        case 'EmailCreate':
        case 'EmailUpdate':
        case 'PermissionIndex':
          if (!store.getters.isSuper) {
            return next('/')
          }
      }

      return next() // all is fine
    }
  } else {
    entryUrl = to.path // store entry url before redirect
    store.commit('storeEntryUrl', entryUrl)
    next('/login')
  }
}

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: loadView('Calendar'),
    beforeEnter: guard
  },
  {
    path: '/create-booking',
    name: 'CreateBooking',
    component: loadView('CreateBooking'),
    beforeEnter: guard
  },
  {
    path: '/requested-bookings',
    name: 'RequestedBookings',
    component: loadView('RequestedBookings'),
    beforeEnter: guard
  },
  {
    path: '/update-booking/:id',
    name: 'UpdateBooking',
    component: loadView('UpdateBooking'),
    beforeEnter: guard
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: loadView('Calendar'),
    beforeEnter: guard
  },
  {
    path: '/users',
    name: 'UserIndex',
    component: loadView('UserIndex'),
    beforeEnter: guard
  },
  {
    path: '/users/create',
    name: 'UserCreate',
    component: loadView('UserCreate'),
    beforeEnter: guard
  },
  {
    path: '/users/update/:id',
    name: 'UserUpdate',
    component: loadView('UserUpdate'),
    beforeEnter: guard
  },
  {
    path: '/projects',
    name: 'ProjectIndex',
    component: loadView('ProjectIndex'),
    beforeEnter: guard
  },
  {
    path: '/projects/create',
    name: 'ProjectCreate',
    component: loadView('ProjectCreate'),
    beforeEnter: guard
  },
  {
    path: '/projects/update/:id',
    name: 'ProjectUpdate',
    component: loadView('ProjectUpdate'),
    beforeEnter: guard
  },
  {
    path: '/locations',
    name: 'LocationIndex',
    component: loadView('LocationIndex'),
    beforeEnter: guard
  },
  {
    path: '/locations/create',
    name: 'LocationCreate',
    component: loadView('LocationCreate'),
    beforeEnter: guard
  },
  {
    path: '/locations/update/:id',
    name: 'LocationUpdate',
    component: loadView('LocationUpdate'),
    beforeEnter: guard
  },
  {
    path: '/patients',
    name: 'PatientIndex',
    component: loadView('PatientIndex'),
    beforeEnter: guard
  },
  {
    path: '/patients/create',
    name: 'PatientCreate',
    component: loadView('PatientCreate'),
    beforeEnter: guard
  },
  {
    path: '/patients/update/:id',
    name: 'PatientUpdate',
    component: loadView('PatientUpdate'),
    beforeEnter: guard
  },
  {
    path: '/my-account',
    name: 'Account',
    component: loadView('Account'),
    beforeEnter: guard
  },
  {
    path: '/permissions',
    name: 'PermissionIndex',
    component: loadView('PermissionIndex'),
    beforeEnter: guard
  },
  {
    path: '/permissions/create',
    name: 'PermissionCreate',
    component: loadView('PermissionCreate'),
    beforeEnter: guard
  },
  {
    path: '/emails',
    name: 'EmailIndex',
    component: loadView('EmailIndex'),
    beforeEnter: guard
  },
  {
    path: '/emails/create',
    name: 'EmailCreate',
    component: loadView('EmailCreate'),
    beforeEnter: guard
  },
  {
    path: '/emails/update/:id',
    name: 'EmailUpdate',
    component: loadView('EmailUpdate'),
    beforeEnter: guard
  },
  {
    path: '/report/:type/:id',
    name: 'Report',
    component: loadView('Report'),
    beforeEnter: guard
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login')
  }
]

export default createRouter({
  history: createWebHistory(),
  routes
})
