import { computed } from 'vue'
import { useStore } from 'vuex'
import { Permission, User } from '@/types.ts'

export function canAccess(
  permission: string,
  { permissions, user }: { permissions: Array<Permission>; user: User }
): boolean {
  if (permissions) {
    const feature = permissions.find(row => {
      return row.name === permission
    })
    if (feature && user) {
      const canAccess =
        'roles' in feature
          ? feature.roles.filter(row => {
              return user.roles.includes(row)
            })
          : []

      if (canAccess.length) return true
    }
  }
  return false
}

export function getPermissions() {
  const store = useStore()
  const canCreateBooking = computed(() =>
    canAccess('Create Booking', store.state)
  )
  const canDeleteBooking = computed(() =>
    canAccess('Delete Booking', store.state)
  )
  const canUpdateBooking = computed(() =>
    canAccess('Update Booking', store.state)
  )
  const canAddPatients = computed(() => canAccess('Add Patients', store.state))
  const canAssignCollectors = computed(() =>
    canAccess('Assign Collectors', store.state)
  )
  const canCloseBooking = computed(() =>
    canAccess('Close Booking', store.state)
  )
  const canConfirmBooking = computed(() =>
    canAccess('Confirm Booking', store.state)
  )
  const canUpdateLab = computed(() => canAccess('Update Lab', store.state))
  const canUpdatePatientResult = computed(() =>
    canAccess('Update Patient Result', store.state)
  )
  const canUpdatePatientStatus = computed(() =>
    canAccess('Update Patient Status', store.state)
  )
  const canUpdateProject = computed(() =>
    canAccess('Update Project', store.state)
  )
  const canViewAllBookings = computed(() =>
    canAccess('View All Bookings', store.state)
  )
  const isClientAdminOnly = computed(() => {
    const user = store.getters.user
    return (
      user.roles.includes('Client Admin') &&
      !user.roles.includes('Booking Admin') &&
      !user.roles.includes('Medical Admin') &&
      !user.roles.includes('Testing Admin') &&
      !user.roles.includes('Super')
    )
  })

  return {
    canCreateBooking,
    canDeleteBooking,
    canUpdateBooking,
    canAddPatients,
    canAssignCollectors,
    canCloseBooking,
    canConfirmBooking,
    canUpdateLab,
    canUpdatePatientResult,
    canUpdatePatientStatus,
    canUpdateProject,
    canViewAllBookings,
    isClientAdminOnly
  }
}
