import { createStore } from 'vuex'
import axios from 'axios'
import router from '../router/index.js'
import { firestore, db, auth } from '@/services/firebase'

const store = createStore({
  state() {
    return {
      refreshToken: null,
      userId: null,
      user: null,
      entryUrl: '/',
      permissions: [],
      calendarView: 'timeGridWeek'
    }
  },
  mutations: {
    authUser(state, userData) {
      state.refreshToken = userData.refreshToken
      state.userId = userData.userId
    },
    storeUser(state, user) {
      state.user = user
    },
    clearAuthData(state) {
      state.refreshToken = null
      state.userId = null
      state.user = null
      state.entryUrl = '/'
      state.permissions = []
    },
    storeEntryUrl(state, url) {
      state.entryUrl = url
    },
    storePermissions(state, permissions) {
      state.permissions = permissions
    },
    storeCalendarView(state, calendarView) {
      state.calendarView = calendarView
    }
  },
  actions: {
    login({ commit, dispatch }, { email, password }) {
      return auth
        .signInWithEmailAndPassword(email, password)
        .then(({ user }) => {
          // Signed in
          localStorage.setItem('refreshToken', user.refreshToken)
          localStorage.setItem('userId', user.uid)
          commit('authUser', {
            refreshToken: user.refreshToken,
            userId: user.uid
          })

          dispatch('fetchUser')
          dispatch('getPermissions')
        })
        .catch(error => Promise.reject(error))
    },
    signup({ commit }, authData) {
      return axios
        .post(
          `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.VUE_APP_APIKEY}`,
          {
            email: authData.email,
            password: authData.password,
            returnSecureToken: true
          }
        )
        .then(res => {
          localStorage.setItem('refreshToken', res.data.refreshToken)
          localStorage.setItem('userId', res.data.localId)

          commit('authUser', {
            refreshToken: res.data.refreshToken,
            userId: res.data.localId
          })

          const { email, name, roles, active } = authData
          const user = { email, name, roles, active }
          firestore
            .collection('users')
            .doc(res.data.localId)
            .set(user)
            .then(() => {
              commit('storeUser', user)
            })
        })
        .catch(error => Promise.reject(error))
    },
    // create users from admin interface
    createUser(context, authData) {
      return axios
        .post(
          `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.VUE_APP_APIKEY}`,
          {
            email: authData.email,
            password: authData.password,
            returnSecureToken: true
          }
        )
        .then(res => {
          // do not store password in user collection
          const { email, name, roles, active, projects } = authData
          const user = { email, name, roles, active, projects }
          firestore.collection('users').doc(res.data.localId).set(user)
        })
    },
    // get user data and save to state
    fetchUser({ commit, state }) {
      if (!state.userId) {
        return
      }

      return firestore
        .collection('users')
        .doc(state.userId)
        .get()
        .then(doc => {
          const user = doc.data()
          user.id = doc.id
          commit('storeUser', user)
          localStorage.setItem('user', JSON.stringify(user))
        })
    },
    autoLogin({ commit }) {
      const refreshToken = localStorage.getItem('refreshToken')
      const userId = localStorage.getItem('userId')
      const user = localStorage.getItem('user')
      const permissions = localStorage.getItem('permissions')

      if (!refreshToken) {
        return
      }

      commit('authUser', { refreshToken, userId })
      commit('storeUser', JSON.parse(user))
      commit('storePermissions', JSON.parse(permissions))
    },
    logout({ commit }) {
      auth.signOut()
      commit('clearAuthData')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userId')
      localStorage.removeItem('user')
      localStorage.removeItem('permissions')
      router.replace('/login')
    },
    getPermissions({ commit, state }) {
      if (!state.userId) {
        return
      }

      const permissions = []

      return db
        .ref('/permissions')
        .once('value')
        .then(data => {
          data.forEach(function (snapshot) {
            const row = snapshot.val()
            row.id = snapshot.key
            permissions.push(row)
          })
          commit('storePermissions', permissions)
          localStorage.setItem('permissions', JSON.stringify(permissions))
        })
    },
    getCalendar({ commit }) {
      const calendarView = localStorage.getItem('calendarView')
      if (calendarView) {
        commit('storeCalendarView', calendarView)
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.userId !== null
    },
    user(state) {
      return state.user
    },
    isSuper(state) {
      return state.user ? state.user.roles.includes('Super') : false
    }
  }
})

export default store
