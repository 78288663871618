<template>
  <div id="app">
    <TheHeader />
    <TheMenu />
    <section id="content">
      <router-view />
    </section>
    <TheFooter />
    <Toast />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheMenu from '@/components/TheMenu'
import TheFooter from '@/components/TheFooter'
import Toast from 'primevue/toast'
import { auth } from '@/services/firebase'

export default {
  name: 'App',
  components: { TheHeader, TheMenu, TheFooter, Toast },
  data() {
    return {
      displayConfirmation: false
    }
  },
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated
    }
  },
  methods: {
    openConfirmation() {
      this.displayConfirmation = true
    },
    closeConfirmation() {
      this.displayConfirmation = false
    }
  },
  created() {
    this.$store.dispatch('autoLogin')
    this.$store.dispatch('getPermissions')
    this.$store.dispatch('getCalendar')
    auth.onAuthStateChanged(async user => {
      if (user) {
        // User is signed in.
        localStorage.setItem('refreshToken', user.refreshToken)
        localStorage.setItem('userId', user.uid)
        this.$store.commit('authUser', {
          refreshToken: user.refreshToken,
          userId: user.uid
        })
        await this.$store.dispatch('fetchUser')

        if (
          this.$store.state.entryUrl !== '/' ||
          this.$router.currentRoute.value.name === 'Login'
        )
          this.$router.push(this.$store.state.entryUrl)
      } else {
        // No user is signed in.
        this.$store.dispatch('logout')
      }
    })
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#content {
  max-width: $app_width;
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 2rem;
}
</style>
