<template>
  <nav id="nav">
    <TabMenu :model="items" />
  </nav>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import TabMenu from 'primevue/tabmenu'
import { canAccess } from '@/helpers/permissions'
export default {
  components: { TabMenu },
  setup() {
    const store = useStore()
    const items = ref([])
    const calendar = {
      label: 'Calendar',
      icon: 'pi pi-fw pi-calendar',
      to: '/calendar'
    }
    const bookings = {
      label: 'Requested Bookings',
      icon: 'pi pi-fw pi-file',
      to: '/requested-bookings'
    }
    const create = {
      label: 'Create Booking',
      icon: 'pi pi-fw pi-plus',
      to: '/create-booking'
    }

    watchEffect(() => {
      if (canAccess('Create Booking', store.state)) {
        items.value = [calendar, bookings, create]
      } else {
        items.value = [calendar, bookings]
      }
    })

    return { items }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
#nav {
  @include app-container;
}
.p-tabmenu {
  ::v-deep(.p-tabmenuitem) {
    margin-top: 2px;
  }
}
</style>
