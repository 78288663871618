<template>
  <svg
    version="1.1"
    id="pmo-logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    viewBox="0 0 344 113"
    style="enable-background: new 0 0 344 113"
    xml:space="preserve"
  >
    <g id="pmo-text">
      <path
        class="st0"
        d="M5.4,57V3h20.4c3.6,0,6.3,0.2,8.2,0.5c2.7,0.4,4.9,1.3,6.7,2.5s3.2,3,4.3,5.2s1.6,4.7,1.6,7.4
		c0,4.6-1.5,8.5-4.4,11.7s-8.2,4.8-15.9,4.8H12.6v22L5.4,57L5.4,57z M12.6,28.7h14c4.6,0,7.9-0.9,9.8-2.6s2.9-4.1,2.9-7.3
		c0-2.3-0.6-4.2-1.7-5.8c-1.1-1.6-2.6-2.6-4.5-3.1c-1.2-0.4-3.4-0.5-6.7-0.5H12.6V28.7z"
      ></path>
      <path
        class="st0"
        d="M54.8,57V3h10.8l12.8,38.3c1.2,3.6,2,6.2,2.6,8c0.6-2,1.6-4.9,2.9-8.7L96.8,3h9.6v54h-6.9V11.8L83.8,57h-6.5
		L61.7,11v46H54.8z"
      ></path>
      <path
        class="st0"
        d="M114.8,30.9c0-8.9,2.4-15.9,7.2-20.9c4.8-5,11-7.6,18.5-7.6c5,0,9.4,1.2,13.4,3.6s7,5.7,9.1,9.9
		s3.1,9,3.1,14.4s-1.1,10.3-3.3,14.6s-5.3,7.5-9.3,9.8c-4,2.2-8.4,3.3-13,3.3c-5.1,0-9.6-1.2-13.5-3.7c-4-2.4-7-5.8-9-10
		C115.9,40.1,114.8,35.6,114.8,30.9z M122.2,31c0,6.5,1.7,11.6,5.2,15.3s7.8,5.6,13.1,5.6s9.7-1.9,13.2-5.6c3.5-3.8,5.2-9.1,5.2-16
		c0-4.4-0.7-8.2-2.2-11.4c-1.5-3.3-3.6-5.8-6.5-7.6c-2.8-1.8-6-2.7-9.6-2.7c-5,0-9.4,1.7-13,5.2C124,17.2,122.2,22.9,122.2,31z"
      ></path>
    </g>
    <g id="gs-text">
      <path
        class="st1"
        d="M7.3,81.1c-2.7,0-4.4-2-4.4-4.4s1.7-4.4,4.3-4.4c1.4,0,2.3,0.4,3.2,1.1l-0.6,0.7c-0.7-0.6-1.4-1-2.6-1
		c-1.9,0-3.3,1.6-3.3,3.5c0,2.1,1.3,3.6,3.4,3.6c1,0,1.9-0.4,2.5-0.9v-2.1H7.2v-0.8h3.5v3.4C9.9,80.5,8.7,81.1,7.3,81.1z"
      ></path>
      <path class="st1" d="M12.8,72.4h1v7.7h4.8V81h-5.8V72.4z"></path>
      <path
        class="st1"
        d="M23.7,81.1c-2.6,0-4.4-2-4.4-4.4s1.8-4.4,4.4-4.4s4.4,2,4.4,4.4C28.1,79,26.3,81.1,23.7,81.1z M23.7,73.1
		c-2,0-3.4,1.6-3.4,3.5c0,2,1.4,3.6,3.4,3.6s3.4-1.6,3.4-3.5C27.1,74.7,25.7,73.1,23.7,73.1z"
      ></path>
      <path
        class="st1"
        d="M36.5,74.5c0,1.1-0.7,1.7-1.4,2c1,0.3,1.8,0.9,1.8,2.1c0,1.5-1.2,2.4-3.1,2.4H30v-8.6h3.7
		C35.4,72.4,36.5,73.2,36.5,74.5z M35.5,74.7c0-0.9-0.7-1.4-1.9-1.4H31v3h2.5C34.7,76.2,35.5,75.7,35.5,74.7z M36,78.6
		c0-0.9-0.8-1.5-2.3-1.5H31v3h2.9C35.2,80.1,36,79.5,36,78.6z"
      ></path>
      <path
        class="st1"
        d="M46.7,81h-1l-1-2.3H40L39,81h-1l3.9-8.6h0.9L46.7,81z M42.4,73.5l-2,4.4h3.9L42.4,73.5z"
      ></path>
      <path class="st1" d="M48.3,72.4h1v7.7h4.8V81h-5.8V72.4z"></path>
      <path
        class="st1"
        d="M62.4,76.2c1.9,0.4,2.8,1.1,2.8,2.4c0,1.5-1.2,2.4-2.9,2.4c-1.4,0-2.5-0.5-3.5-1.4l0.6-0.7
		c0.9,0.8,1.8,1.2,2.9,1.2c1.2,0,1.9-0.6,1.9-1.5c0-0.8-0.4-1.3-2.2-1.6c-2-0.4-2.9-1.1-2.9-2.5s1.2-2.4,2.8-2.4
		c1.2,0,2.1,0.4,3,1.1l-0.6,0.7c-0.8-0.6-1.6-0.9-2.5-0.9c-1.1,0-1.8,0.6-1.8,1.4C60.1,75.4,60.5,75.8,62.4,76.2z"
      ></path>
      <path
        class="st1"
        d="M73.2,73.3h-5.2v2.9h4.7v0.9h-4.7v3h5.3V81h-6.3v-8.6h6.2V73.3z"
      ></path>
      <path
        class="st1"
        d="M81,81l-2.5-3.3H76V81h-1v-8.6h3.7c1.9,0,3.1,1,3.1,2.6c0,1.4-1,2.3-2.3,2.5l2.6,3.5H81z M78.8,73.3h-2.6v3.5
		h2.6c1.3,0,2.2-0.7,2.2-1.8C80.9,73.9,80.1,73.3,78.8,73.3z"
      ></path>
      <path
        class="st1"
        d="M90.1,72.4h1L87.4,81h-0.9l-3.6-8.6h1l3.1,7.4L90.1,72.4z"
      ></path>
      <path class="st1" d="M93.8,72.4V81h-1v-8.6H93.8z"></path>
      <path
        class="st1"
        d="M100,81.1c-2.5,0-4.3-1.9-4.3-4.4s1.8-4.4,4.3-4.4c1.6,0,2.5,0.6,3.3,1.4l-0.7,0.7c-0.7-0.7-1.5-1.2-2.7-1.2
		c-1.9,0-3.3,1.5-3.3,3.5s1.4,3.6,3.3,3.6c1.2,0,1.9-0.5,2.8-1.2l0.6,0.6C102.6,80.5,101.6,81.1,100,81.1z"
      ></path>
      <path
        class="st1"
        d="M111.2,73.3h-5.2v2.9h4.7v0.9h-4.7v3h5.3V81h-6.3v-8.6h6.2V73.3z"
      ></path>
      <path
        class="st1"
        d="M116.2,76.2c1.9,0.4,2.8,1.1,2.8,2.4c0,1.5-1.2,2.4-2.9,2.4c-1.4,0-2.5-0.5-3.5-1.4l0.6-0.7
		c0.9,0.8,1.8,1.2,2.9,1.2c1.2,0,1.9-0.6,1.9-1.5c0-0.8-0.4-1.3-2.2-1.6c-2-0.4-2.9-1.1-2.9-2.5s1.2-2.4,2.8-2.4
		c1.2,0,2.1,0.4,3,1.1l-0.6,0.7c-0.8-0.6-1.6-0.9-2.5-0.9c-1.1,0-1.8,0.6-1.8,1.4C113.9,75.4,114.3,75.8,116.2,76.2z"
      ></path>
    </g>
    <linearGradient
      id="arrow_1_"
      gradientUnits="userSpaceOnUse"
      x1="174.0242"
      y1="94.7815"
      x2="235.7879"
      y2="1.4459"
      gradientTransform="matrix(1 0 0 -1 0 110)"
    >
      <stop offset="0" style="stop-color: #0062b3"></stop>
      <stop offset="0.1176" style="stop-color: #0074bc"></stop>
      <stop offset="0.3441" style="stop-color: #0091cb"></stop>
      <stop offset="0.569" style="stop-color: #00a6d6"></stop>
      <stop offset="0.7894" style="stop-color: #00b3dd"></stop>
      <stop offset="1" style="stop-color: #00b7df"></stop>
    </linearGradient>
    <path
      id="arrow"
      class="st2"
      d="M169.2,2.5c42.5,48.2-47,95.6-78.5,108.1L341,46.4L169.2,2.5z"
    ></path>
  </svg>
</template>
<script>
export default {
  name: 'Logo',
  props: {
    color: {
      type: String,
      default: 'white', // green, green--outline, white
      required: false
    },
    height: {
      type: Number,
      default: 113,
      required: false
    },
    width: {
      type: Number,
      default: 344,
      required: false
    }
  }
}
</script>
<style type="text/css">
.st0 {
  fill: #fff;
  /* fill: #0a4c82; */
}
.st1 {
  fill: #fff;
  /* fill: #2c3f47; */
}
.st2 {
  fill: url(#arrow_1_);
}
</style>
